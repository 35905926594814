<template>
  <VDialog
    v-model="isOpen"
    content-class="preview"
  >
    <img
      class="preview__img"
      :src="src"
      alt=""
    >
  </VDialog>
</template>

<script>

export default {
  name: 'TrainingPreview',
  data () {
    return {
      isOpen: false,
      src: ''
    }
  },

  created () {
    this.register()
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    preview (evt) {
      const target = evt.target
      if (target && target.nodeType === Node.ELEMENT_NODE && target.hasAttribute('preview-src')) {
        switch (target.nodeName) {
          case 'A':
          case 'IMG':
            evt.stopPropagation()
            evt.preventDefault()

            this.src = target.getAttribute('preview-src')
            this.isOpen = true
            break
        }
      }
    },

    register () {
      document.addEventListener('click', this.preview)
    },

    unregister () {
      document.removeEventListener('click', this.preview)
      this.isOpen = false
      this.src = ''
    }
  }
}
</script>

<style lang="scss">
.preview {
  box-shadow: none;

  &__img {
    width: auto;
    display: block;
    margin: 0 auto;
  }
}

[preview-src] {
  cursor: zoom-in;
}
</style>
