<template>
  <VProgressLinear
    :class="['training-timer', `${color}--text`]"
    :color="color"
    background-opacity="0"
    :value="progress"
  >
    {{ $t('left.one') }}:&nbsp;<time>{{ value }}</time>
  </VProgressLinear>
</template>

<script>
import { mapActions } from 'vuex'

import { UDate } from '@/utils/date'
import { formatInt } from '@/utils/common'
import * as actions from '@/store/actions/types'

export default {
  name: 'TrainingTimer',

  props: {
    bufferValue: {
      type: Number,
      default: 0
    },

    // На вход подавать время в секундах
    seconds: {
      type: Number,
      default: 0
    },

    on: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      time: 0,
      timer: null
    }
  },

  computed: {
    progress () {
      return this.time * 100 / this.bufferValue
    },

    color () {
      if ((this.time / UDate.minuteInSeconds) >= 10) {
        return 'info'
      } else if ((this.time / UDate.minuteInSeconds) >= 5) {
        return 'warning'
      } else {
        return 'error'
      }
    },

    value () {
      const units = {
        hours: ['час', 'часа', 'часов'],
        minutes: ['минута', 'минуты', 'минут'],
        seconds: ['секунда', 'секунды', 'секунд']
      }

      let secondsLeft = this.time

      const hoursLeft = Math.floor(secondsLeft / UDate.hourInSeconds)
      secondsLeft -= hoursLeft * UDate.hourInSeconds

      const minutesLeft = Math.floor(secondsLeft / UDate.minuteInSeconds)
      secondsLeft -= minutesLeft * UDate.minuteInSeconds

      return [
        (hoursLeft > 0) ? formatInt(hoursLeft, units.hours) : '',
        (minutesLeft > 0) ? formatInt(minutesLeft, units.minutes) : '',
        (minutesLeft < 5) ? formatInt(secondsLeft, units.seconds) : ''
      ].join(' ').trim()
    }
  },

  watch: {
    on: {
      handler: 'checkOn',
      immediate: true
    },

    seconds: {
      handler: 'update',
      immediate: true
    }
  },

  beforeDestroy () {
    this.unregister()
  },

  methods: {
    ...mapActions({
      createNotification: actions.CREATE_NOTIFICATION
    }),

    start () {
      this.timer = setInterval(() => {
        if (this.time > 0) {
          this.time--
        } else {
          this.stop()
        }
      }, 1000)
    },

    stop () {
      if (!this.timer || this.time > 0) {
        return
      }

      clearInterval(this.timer)
      this.createNotification({ msg: 'Ваше время истекло', type: 'error' })
      this.$emit('stop')
    },

    checkOn (val) {
      if (val) {
        return this.start()
      }

      return this.stop()
    },

    update (val) {
      if (val) {
        this.time = val
      }
    },

    unregister () {
      if (!this.timer) {
        return
      }

      clearInterval(this.timer)
      this.timer = null
      this.time = 0
    }
  }
}
</script>
<style lang="scss">
  .training-timer {
    font-size: 0.875rem;
    text-transform: uppercase;
    height: 2rem !important;
    border: 1px solid currentColor;

    .v-progress-linear__determinate {
      opacity: 0.2;
    }
  }
</style>
