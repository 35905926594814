<template>
  <aside
    v-if="session && session.steps && session.steps.length"
    class="training-steps"
  >
    <VNavigationDrawer
      v-bind="props"
      v-on="events"
    >
      <template #prepend>
        <VBtn
          v-if="!isOpenSteps"
          color="transparent"
          block
          title="Раскрыть список уроков"
          @click.stop="toggleSteps(!isOpenSteps)"
        >
          Все уроки
          <VIcon>chevron_right</VIcon>
        </VBtn>

        <VSubheader v-else>
          <span>Все уроки:</span>
          <VSpacer />
          <VBtn
            icon
            title="Скрыть список уроков"
            @click.stop="toggleSteps(!isOpenSteps)"
          >
            <VIcon>close</VIcon>
          </VBtn>
        </VSubheader>
      </template>

      <SessionSteps
        v-show="isOpenSteps"
        v-model="session.steps"
        preview
        dense
        class="fill-height"
      />
      <template #append>
        <footer class="training-steps__footer">
          <template v-if="session.is_active && !session.is_completed">
            <SessionAcceptForm
              :session="session"
              block
            />
            <VBtn
              v-if="!isHiddenNextButton"
              color="success"
              block
              @click="$emit('next')"
            >
              {{ $t('next_lesson.one') }}
              <VIcon right>
                navigate_next
              </VIcon>
            </VBtn>
          </template>

          <VBtn
            v-else
            color="success"
            block
            :disabled="isLastLesson"
            @click="$emit('next')"
          >
            {{ $t('next_lesson.one') }}
            <VIcon right>
              navigate_next
            </VIcon>
          </VBtn>
        </footer>
      </template>
    </VNavigationDrawer>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'
import SessionSteps from '@components/SessionSteps.vue'
import SessionAcceptForm from '@components/SessionAcceptForm.vue'

export default {
  name: 'TrainingSteps',
  components: {
    SessionSteps,
    SessionAcceptForm
  },

  props: {
    isHiddenNextButton: {
      type: Boolean,
      default: false
    },
    isLastLesson: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      session: getters.SESSION,
      isOpenSteps: getters.IS_OPEN_STEPS
    }),

    events () {
      const { mdAndUp } = this.$vuetify.breakpoint
      return mdAndUp ? {} : { input: this.toggleSteps }
    },

    props () {
      const props = {
        tag: 'div'
      }

      if (this.$vuetify.breakpoint.mdAndUp) {
        return Object.assign(props, {
          value: !this.isOpenSteps,
          stateless: true,
          permanent: true,
          width: '20rem',
          'mini-variant-width': '7rem',
          'mini-variant': !this.isOpenSteps
        })
      }

      return Object.assign(props, {
        value: this.isOpenSteps,
        bottom: true,
        fixed: true
      })
    }
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      toggleSteps: actions.TOGGLE_STEPS
    }),

    register () {
      const { smAndDown } = this.$vuetify.breakpoint
      if (!smAndDown) {
        this.toggleSteps(JSON.parse(localStorage.getItem('isOpenSteps')))
      }
    }
  }
}
</script>

<style lang="scss">
  .training-steps {
    @media #{map-get($display-breakpoints, 'md-and-up')} {
      top: var(--app-bar-height);
      position: sticky;
      height: calc(100vh - var(--app-bar-height));
      flex-shrink: 0;
      overflow-y: auto;
      border-top-right-radius: $border-radius-root;
      border-bottom-right-radius: $border-radius-root;
    }

    .v-navigation-drawer--mini-variant {
      .v-btn {
        padding: 0.5rem 0;
        border-radius: 0;
        box-shadow: none;
        flex: 0 1 auto;
        font-size: 0.75rem;
        max-width: 168px;
        min-width: 80px;
        position: relative;
        text-transform: none;
        height: 5rem;
        white-space: normal;

        .v-btn__content {
          flex-direction: column;
          height: inherit;
          max-width: 100%;
        }

        .v-icon {
          margin: 0 0 0.25rem;
          order: -1;
        }
      }

      .training-steps__footer {
        padding: 0;
      }
    }
  }

  .training-steps__footer {
    display: grid;
    gap: 0.5rem;
    padding: 0.5rem;
  }
</style>
